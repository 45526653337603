<template>
  <div id="data-model-container" class="vs-con-loading__container">
    <div id="data-list-list-view" class="data-list-container">
      <vs-table stripe :data="batesResponse.slices" :hover-flat="true">
        <template slot="header">
          <div class="header-title-container flex items-center">
            <!-- ADD NEW -->
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer mr-2"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              @click.stop="goBack()" 
            />
            <span class="header-title">Bates Report</span>
          </div>
          <div>Firm: {{batesResponse.lawFirmName}}</div>
          <div>Case: {{batesResponse.caseName}}</div>
        </template>
          <template slot="thead">
            <vs-th v-for="header in headerItems" :key="header" class="text-center">{{header}}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr 
                :data="tr" 
                :key="indextr" v-for="(tr, indextr) in batesResponse.slices"
              >
                <vs-td>
                  <p class="product-name font-medium">{{ tr.title }}</p>
                </vs-td>
                <div v-for="(group, indexgr) in tr.batesGroups" :key="indexgr" style="display: contents;">
                  <vs-td>{{group.groupName}} {{group.availableBatesInfo.length > 0 ? group.availableBatesInfo[0] : ""}} {{group.availableBatesInfo.length > 1 ? (" - " + group.availableBatesInfo[group.availableBatesInfo.length - 1]) : ""}}</vs-td>
                  <vs-td>
                    <span v-for="(missing, indexmr) in group.missingBatesInfo" :key="indexmr">
                      <a @click.stop="previewDocumentWithMissingBates(missing, group.groupName, tr.documentId)">{{missing}}</a>
                      <span >{{(group.missingBatesInfo.length - 1) > indexmr ? ", " : ""}}</span>
                    </span>
                  </vs-td>
                </div>
                <vs-td>
                  <vx-tooltip text="Download" position="left" color="#1a1a1a">
                    <feather-icon icon="DownloadIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-2"
                                  @click.stop="downloadDocumentLink(tr)" />
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
    </div>
  </div>
</template>

<script>
import axios from '@/axios.js'
import moduleTodo from '@/store/todo/moduleTodo.js'
import _ from 'lodash'

export default {
  components: {
    
  },
  data () {
    return {
      batesResponse: {slices: []},
      maxColumns: 1,
      headerItems: [],
      accountId: this.$route.params.accountId,
      caseId: this.$route.params.caseId,
    }
  },
  created() {
    if (!this.$store.hasModule("todo")) {
      this.$store.registerModule('todo', moduleTodo)
    }
  },
  mounted () {
    this.headerItems = ["Title"];
    this.$vs.loading({
      container: '#data-model-container',
    });
    const batesUrl = "accounts/" + this.accountId + "/cases/" + this.caseId + "/bates";
    axios.get(batesUrl)
      .then(response => {
        if (response && response.data) {
          this.batesResponse = response.data;
          if (this.batesResponse.slices) {
            this.batesResponse.slices.map(slice => {
              if (slice.batesGroups && Object.keys(slice.batesGroups).length > this.maxColumns) {
                this.maxColumns = Object.keys(slice.batesGroups).length;
              }
            });
            for (var i=0; i<this.maxColumns; i++) {
              this.headerItems.push("Bates #" + (i+1));
              this.headerItems.push("Missing #" + (i+1));
            }
            this.headerItems.push("Actions");
          }
        }
        this.$vs.loading.close("#data-model-container > .con-vs-loading")
      }).catch(error => {
        console.log(error);
      });
  },
  methods: {
    previewDocumentLink(slice) {

    },
    download(url) {
      let a = document.createElement("a");
      a.setAttribute('href', url);
      a.setAttribute('download', '');
      a.setAttribute('target', '_blank');
      a.click();
    },
    downloadDocumentLink(slice) {
      this.$vs.loading({
        container: '#data-model-container',
      });
      const downloadUrl = "accounts/" + this.accountId + "/cases/" + this.caseId 
        + "/documents/" + slice.documentId + "/slices/" + slice.sliceId + "/download";
      axios.get(downloadUrl)
        .then(downloadresponse => {
          this.$vs.loading.close("#data-model-container > .con-vs-loading");
          if (downloadresponse && downloadresponse.data) {
            this.download(downloadresponse.data);
          }
        }).catch(error => {
          console.log(error);
          this.$vs.loading.close("#data-model-container > .con-vs-loading");
        })
    },
    goBack() {
      this.$router.push({name: "account-cases", params: {accountId: this.accountId}});
    },
    previewDocumentWithMissingBates(batesNumber, batesGroupName, documentId) {
      this.$router.push({
        name: "case-document-screenshots", 
        params: {accountId: this.accountId, caseId: this.caseId, documentId: documentId}, 
        query: {
          bates: batesGroupName + batesNumber
        }
      })
    }
  },
}
</script>

<style>
  .vs-tooltip{
    padding: 8px;
  }
  #data-list-list-view .vs-con-table .vs-table--header {
    margin: 1rem;
    font-size: 12px;
  }

  .vs-table--search {
    max-width: 100%;
    width: 100%;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
    padding: 0.65rem 2rem;
    width: 100%;
    margin-right: 18px;
  }

  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input + i,
  #data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input:focus + i {
    left: 0.5rem;
  }

  .vs-table--td {
    padding: 10px 20px !important;
  }

  .header-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-title-container {
    flex-grow: unset;
  }

  .header-title-container .feather-icon {
    height: 20px;
    margin-right: 8px;
  }

  .vs-table--tbody-table .tr-values.selected {
    cursor: auto;
  }

  .vs-table--tbody-table a {
    cursor: pointer;
    text-decoration: underline;
  }
</style>